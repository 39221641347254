.kyc {
    font-family: 'Montserrat', sans-serif;
    width: 85%;
    margin: 0 auto; 
    padding: 20px; 
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 10px; 
}

.kyc > h2.kyc-header {
    text-align: center;
    font-weight: 700;
    margin: 36px 0;
    font-size: 28px; 
}

.validation-section {
    margin-bottom: 40px;
}

.validation-section > h2 {
    margin-bottom: 20px;
    font-size: 24px; 
    text-align: center; 
}

.button-row {
    display: flex;
    justify-content: space-between; 
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; 
}

.button-container {
    flex: 1;
    display: flex;
    justify-content: center; 
    margin-bottom: 3%; 
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15em;
    padding: 10px;
    background-color: #1c6ade;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 350;
    border: none;
    padding: 4px 14px;
    border-radius: 5px;
    box-shadow: 4px 4px 7px rgb(20, 20, 20);
    max-width: 200px; 
}

.button:hover {
    background-color: #0056b3;
}

.external-link-icon {
    margin-left: 8px;
    font-size: 14px; 
}

@media (max-width: 720px) {
    .button-row {
        justify-content: center;
    }
}
