
.news {
  font-family: 'Montserrat', sans-serif;
  width: 85%;
  margin: 0 auto;
  padding: 20px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 10px;
}

.news-main-header {
  text-align: center;
  font-weight: 700;
  margin: 36px 0;
  font-size: 28px;
}

.news-link-container {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.news-header {
  flex: 1;
  font-size: 1.3em;
}

.news-anchor {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #1c6ade;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  flex: 1;
  text-align: center;
}

.news-anchor:hover {
  background-color: #103a7a;
  color: white;
}

.news-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

@media (max-width: 720px) {
  .news {
    justify-content: center;
    font-size: 0.5em;
  }

  .news-link-container {
    flex-direction: column; 
    align-items: flex-start;
    text-align: left; 
  }

  .news-anchor {
    margin-top: 10px; 
    justify-content: center;
    width: 100%; 
  }

  .news-logo {
    margin-right: 15px; 
    margin-bottom: 10px; 
  }
}
