@keyframes bounceIn {
    from, 20%, 40%, 60%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

.text-animate, .text-animate-hover {
    display: inline-block;
    min-width: 10px;
    font-size: 500%;
    font-weight: bolder;
    color: #1c6ade;
    text-shadow: 5px 1.7px #00b386;
}

.text-animate {
    opacity: 0;
    animation: bounceIn 0.1s 0.5s forwards ease-in;
}

.text-animate-hover {
    animation-fill-mode: both;
}

.text-animate-hover:hover {
    animation: rubberBand 1s;
    color: #FFC977; 
}

.text-animate._1 {
    animation-delay: 0.1s;
}

.text-animate._2 {
    animation-delay: 0.2s;
}

.text-animate._3 {
    animation-delay: 0.3s;
}

.text-animate._4 {
    animation-delay: 0.4s;
}

.text-animate._5 {
    animation-delay: 0.5s;
}

.text-animate._6 {
    animation-delay: 0.6s;
}

.text-animate._7 {
    animation-delay: 0.7s;
}

.text-animate._8 {
    animation-delay: 0.8s;
}

.text-animate._9 {
    animation-delay: 0.9s;
}

.text-animate._10 {
    animation-delay: 1s;
}

.text-animate._11 {
    animation-delay: 1.1s;
}

.text-animate._12 {
    animation-delay: 1.2s;
}

.text-animate._13 {
    animation-delay: 1.3s;
}

.text-animate._14 {
    animation-delay: 1.4s;
}

.text-animate._15 {
    animation-delay: 1.5s;
}

.text-animate._16 {
    animation-delay: 1.6s;
}

.text-animate._17 {
    animation-delay: 1.7s;
}

.text-animate._18 {
    animation-delay: 1.8s;
}

.text-animate._19 {
    animation-delay: 1.9s;
}

.text-animate._20 {
    animation-delay: 2s;
}

.text-animate._21 {
    animation-delay: 2.1s;
}

.text-animate._22 {
    animation-delay: 2.2s;
}

.text-animate._23 {
    animation-delay: 2.3s;
}

.text-animate._24 {
    animation-delay: 2.4s;
}

.text-animate._25 {
    animation-delay: 2.5s;
}

.text-animate._26 {
    animation-delay: 2.6s;
}

.text-animate._27 {
    animation-delay: 2.7s;
}

.text-animate._28 {
    animation-delay: 2.8s;
}

.text-animate._29 {
    animation-delay: 2.9s;
}

.text-animate._30 {
    animation-delay: 3s;
}

.text-animate._31 {
    animation-delay: 3.1s;
}

.text-animate._32 {
    animation-delay: 3.2s;
}

.text-animate._33 {
    animation-delay: 3.3s;
}

.text-animate._34 {
    animation-delay: 3.4s;
}

.text-animate._35 {
    animation-delay: 3.5s;
}


@media screen and (max-width: 1200px) {
    .animated-wealth-content {
        font-size: 0.7em;
    }
}

@media screen and (max-width: 1000px) {
    .animated-wealth-content {
        font-size: 0.6em;
    }
}

@media screen and (max-width: 800px) {
    .why-your {
        font-size: 1.3em;
    }
    .animated-wealth-content {
        font-size: 0.4em;
    }
}